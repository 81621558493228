import { useState, useEffect, useCallback, FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Alert, Button, Form, Input, Modal, Tabs } from '@maxtropy/components';
import { Tag } from 'antd';
import qs from 'qs';

import { getEncrypt, login, LoginParams } from '../../api';

import { useLoginSMSToken } from '../../components/SendSMSToken';

import welcomeWebp from '../../assets/video/welcome.webm';
import user from '../../assets/images/user.svg';
import lock from '../../assets/images/lock.svg';
import phone from '../../assets/images/phone.svg';
import code from '../../assets/images/code.svg';

import styles from './index.module.scss';
import Helmet from 'react-helmet';
import { BusinessCodeError, ResponseCodeError } from '@maxtropy/components';
import policeImg from '../../assets/images/police.png';
import { encrypt } from './encrypt';

const { TabPane } = Tabs;
const { useForm } = Form;

// const rememberUserNamekey = 'cas-login:rememberUserName';
// const userNameKey = 'cas-login:username';

const commonPhoneRegex = /^(?:1\d{10}|0\d{2,3}-?\d{7,8})$/;

interface CodeParams {
  cellphone: string;
  code: string;
}

const Login: FC = () => {
  const [form] = useForm();
  const [formCode] = useForm();
  // const [userName, setUserName] = useState<string>('');
  // const [remeber, setRemeber] = useState<boolean>(false);
  const [err, setErr] = useState<string>('');
  const location = useLocation();

  const { canSendSMS, sendSMS, smsSending, smsCoolDown, setCellphone } = useLoginSMSToken();

  // useEffect(() => {
  //   const rememberUserName = localStorage.getItem(rememberUserNamekey);
  //   if (rememberUserName === null) {
  //     setRemeber(true);
  //   } else if (rememberUserName === 'true') {
  //     const userName = localStorage.getItem(userNameKey) || '';
  //     setUserName(userName);
  //     setRemeber(true);
  //   }
  // }, []);

  // useEffect(() => {
  //   form.setFieldsValue({
  //     username: userName,
  //   });
  // }, [form, userName]);

  useEffect(() => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (query.thirdAuth) {
      const userInfo = window.atob(query.thirdAuth as string);
      const username = userInfo.split(':')?.[0];
      const password = userInfo.split(':')?.[1];

      onPassFinish({ username, password });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const errorHandler = useCallback(
    (e: Response | ResponseCodeError | BusinessCodeError, defaultDesc = '请输入正确的账号或手机号和密码。') => {
      if (e.status === 403) {
        // setErr('这个账户被禁用了。');
        Modal.warning({
          className: styles.modal,
          content: <div style={{ width: 250 }}>您的账号已禁用，请核实是否输入错误或联系管理员进行开通。</div>,
          okText: '关闭',
          mask: false,
        });
      } else if (e.status === 423) {
        // 跳转页面
        // setErr('这个账户被冻结了。');
        Modal.warning({
          className: styles.modal,
          content: <div style={{ width: 250 }}>您的账号已冻结，请核实是否输入错误或联系管理员进行开通。</div>,
          okText: '关闭',
          mask: false,
        });
        // history.push('/activate?back=true');
      } else if (e.status === 417) {
        let errMsg = defaultDesc;
        if (e instanceof ResponseCodeError) {
          errMsg = e.message || e.errorMessage || defaultDesc;
        }
        Modal.warning({
          className: styles.modal,
          content: <div style={{ width: 250 }}>{errMsg}</div>,
          okText: '关闭',
          mask: false,
        });
        // history.push('/activate?back=true');
      } else if (e.status === 401) {
        setErr(defaultDesc);
      }

      if (e.status >= 40000 && e.status <= 69999) {
        Modal.error({
          className: styles.modal,
          content: <div style={{ width: 250 }}>系统异常</div>,
          okText: '关闭',
          mask: false,
        });
      }
      return;
    },
    []
  );

  const loginFn = useCallback(
    (text: string, defaultErrMsg?: string) => {
      setErr('');
      getEncrypt()
        .then(res => {
          const resJson = JSON.parse(res);
          if (resJson?.code !== 0) {
            throw new BusinessCodeError(resJson?.code, resJson?.msg, resJson);
          }
          const publicKey = resJson?.data?.key;
          if (publicKey) {
            return encrypt(publicKey, text);
          }
        })
        .then(encryptText => {
          if (encryptText) {
            return login(encryptText);
          }
        })
        .catch(e => {
          errorHandler(e, defaultErrMsg);
        });
    },
    [errorHandler]
  );

  const onPassFinish = (values: LoginParams) => {
    // if (remeber) {
    //   localStorage.setItem(rememberUserNamekey, 'true');
    //   localStorage.setItem(userNameKey, values.username);
    // } else {
    //   localStorage.setItem(rememberUserNamekey, 'false');
    //   localStorage.removeItem(userNameKey);
    // }
    loginFn(
      JSON.stringify({
        username: values.username.trim(),
        password: values.password,
        UA: /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ? 'MOBILE' : 'PC',
        timestamp: Date.now(),
      })
    );
  };

  const onCodeFinish = (value: CodeParams) => {
    loginFn(
      JSON.stringify({
        username: `OTP_SMS/${value.cellphone}`,
        password: value.code,
        UA: /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ? 'MOBILE' : 'PC',
        timestamp: Date.now(),
      }),
      '验证码不正确'
    );
  };

  return (
    <div className={styles.loginWrapper}>
      <Helmet>
        <title>登录</title>
      </Helmet>
      <div className={styles.content}>
        <div className={styles.loginHeader}>
          <div className={styles.headerTitle}>{window?.SLOGAN ?? '工业赋能 模式创新'}</div>
          <div className={styles.headerSubTitle}>打造闭环赋能体系，共建工业互联网生态，落地产业应用！</div>
        </div>
        <div className={styles.display} id="display">
          {/* <div className="display-image"></div> */}
          <video id="display-video" className={styles.displayVideo} muted src={welcomeWebp} autoPlay loop></video>
        </div>
        <div id={styles.loginForm} style={{ boxSizing: 'border-box' }}>
          <div className={styles.cardForm}>
            <div className={styles.cardFormTitle}>
              <h2 className={styles.mainTitle}>{window?.LOGINTITLE ?? '熵云工业互联网统一登录中心'}</h2>
              <h5 className={styles.subTitle}>欢迎登录</h5>
            </div>
            {err && (
              <Alert
                message={err}
                type="error"
                showIcon
                closable
                afterClose={() => {
                  setErr('');
                }}
              />
            )}
            <div className={styles.carFormBody}>
              <Tabs
                defaultActiveKey="1"
                tabBarStyle={{ marginBottom: 30 }}
                className={styles.tabs}
                onChange={(activeKey: string) => {
                  setErr('');
                }}
              >
                <TabPane tab="账号密码登录" key="1">
                  <div className="formPass">
                    <Form form={form} onFinish={onPassFinish} isOrigin>
                      <Form.Item
                        name="username"
                        rules={[
                          { required: true, message: '账号不能为空' },
                          { whitespace: true, message: '账号不能为空字符' },
                        ]}
                      >
                        <Input
                          placeholder="账号/手机号"
                          size="large"
                          prefix={<img src={user} className="basic-form-prefix-icon" alt="" />}
                        />
                      </Form.Item>
                      <Form.Item
                        name="password"
                        rules={[
                          { required: true, message: '密码不能为空' },
                          { whitespace: true, message: '密码不能为空字符' },
                        ]}
                      >
                        <Input.Password
                          placeholder="密码"
                          size="large"
                          prefix={<img src={lock} className="basic-form-prefix-icon" alt="" />}
                        />
                      </Form.Item>
                      <div className={styles.checkboxWrapper}>
                        {/* <Checkbox
                          checked={remeber}
                          onChange={e => {
                            setRemeber(e.target.checked);
                          }}
                        >
                          记住账号
                        </Checkbox> */}
                        <span />
                        <Link to="/forgot-password?back=true">忘记密码？</Link>
                      </div>
                      <Form.Item>
                        <Button
                          htmlType="submit"
                          wrapStyle={{ width: '100%' }}
                          style={{ width: '100%' }}
                          type="primary"
                          size="large"
                        >
                          登录
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </TabPane>
                <TabPane tab="手机号验证码登录" key="2">
                  <Form isOrigin form={formCode} onFinish={onCodeFinish}>
                    <Form.Item
                      name="cellphone"
                      rules={[
                        { pattern: commonPhoneRegex, message: '请输入正确的手机号' },
                        { required: true, message: '手机号不能为空' },
                        { whitespace: true, message: '手机号不能为空字符' },
                      ]}
                    >
                      <Input
                        placeholder="手机号"
                        size="large"
                        autoComplete="off"
                        prefix={<img src={phone} className="basic-form-prefix-icon" alt="" />}
                        onChange={e => setCellphone(e.target.value)}
                      />
                    </Form.Item>
                    <Form.Item
                      name="code"
                      rules={[
                        { required: true, message: '验证码不能为空' },
                        { whitespace: true, message: '验证码不能为空字符' },
                      ]}
                    >
                      <Input
                        placeholder="输入验证码"
                        size="large"
                        autoComplete="off"
                        prefix={<img src={code} className="basic-form-prefix-icon" alt="" />}
                        suffix={
                          <Button type="link" onClick={sendSMS} disabled={!canSendSMS} loading={smsSending}>
                            {smsSending ? '发送中' : smsCoolDown > 0 ? `${smsCoolDown}秒后可重发` : '获取验证码'}
                          </Button>
                        }
                      />
                    </Form.Item>
                    {/* <Captcha className={styles.captcha}/> */}
                    <Form.Item>
                      <Button
                        wrapStyle={{ width: '100%' }}
                        style={{ width: '100%' }}
                        htmlType="submit"
                        className={styles.button}
                        type="primary"
                        size="large"
                      >
                        登录
                      </Button>
                    </Form.Item>
                  </Form>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      {/* <p className={styles.bottomText}>DbPE™-CPS Copyright
        <CopyrightCircleOutlined /> 2017~{new Date().getFullYear()}{" "}</p> */}
      <div className={styles.bottomText}>
        <div className={styles.bottomPolice}>
          <a href={window.AUTHPOLOCEURL} className={styles.policeHref} target="_blank" rel="noreferrer">
            <img src={policeImg} alt="" style={{ float: 'left' }} />
            <p className={styles.policeTxt}>{window.AUTHPOLICETXT}</p>
          </a>
        </div>
        <a href={window.AUTHLICENSEURL}>{window.AUTHICPLICENSETXT}</a>
      </div>
    </div>
  );
};

export default Login;
